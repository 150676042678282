<template>
  <el-dialog
      :title="params.title"
      :model-value="params.show"
      @close="onCacel"
      width="800px" center>
    <el-form :model="form" :rules="rules" ref="form" label-width="120px" class="ruleForm">
      <div style="display: grid; grid-template-columns: repeat(2, 50%);" >
        

        <el-form-item label="密码" prop="password">
         <el-input v-model.trim="form.password" placeholder="请输入密码" show-password></el-input>
        </el-form-item>

        <el-form-item label="用户名" prop="username">
         <el-input v-model.trim="form.username" placeholder="请输入用户名"></el-input>
        </el-form-item>

        <el-form-item label="角色"  prop="role">
          <SelectLink url="/Role/List" :selected="form.role" field="name" @change="(val)=>{this.form.role = val.value;this.form.role_id = val.key}"></SelectLink>
        </el-form-item>

        <el-form-item label="姓名" prop="realNamer">
         <el-input v-model.trim="form.realNamer" placeholder="请输入姓名"></el-input>
        </el-form-item>

        <el-form-item label="生日" prop="birthday">
          <el-date-picker
              v-model="form.birthday"
              type="date"
              value-format="YYYY-MM-DD"
              placeholder="选择日期">
              </el-date-picker>
        </el-form-item>

<!--        <el-form-item label="学历" prop="education">-->
<!--         <el-select v-model="form.education" placeholder="请选择学历" style="width: 100%">-->
<!--          <el-option key="请选择" label="请选择" value=""/>-->
<!--          <el-option v-for="item in global.dict.UserEducation" :key="item" :label="item" :value="item"></el-option>-->
<!--         </el-select>-->
<!--        </el-form-item>-->

        <el-form-item label="身份证" prop="idCard">
         <el-input v-model.trim="form.idCard" placeholder="请输入身份证"></el-input>
        </el-form-item>

        <el-form-item label="性别" prop="sex">
         <el-select v-model="form.sex" placeholder="请选择性别" style="width: 100%">
          <el-option key="请选择" label="请选择" value=""/>
          <el-option v-for="item in global.dict.Sex" :key="item" :label="item" :value="item"></el-option>
         </el-select>
        </el-form-item>

        <el-form-item label="手机号码" prop="phone">
         <el-input v-model.number="form.phone" placeholder="请输入手机号码"></el-input>
        </el-form-item>

        <el-form-item label="电子邮箱" prop="email">
         <el-input  v-model="form.email" placeholder="请输入电子邮箱"></el-input>
        </el-form-item>


      </div>
      <el-form-item>
        <el-button type="primary" @click="submitForm('form')" >保存</el-button>
        <el-button @click="onCacel()">返回</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import axios from '@/utils/axios'
import {ElMessage} from 'element-plus'
import SelectLink from "@/components/SelectLink"

export default {
  name: "Form",
  // 继承父窗体列表页的数据
  props: {
    params: Object
  },
  data() {
    return {
      // 全局变量，用于获得配置信息等
      global: window.global,

      // 更多选项
      option: {
    
        // 角色
        role: [],
  },
    // 验证选项
    rules: {
      email: [
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change'],
          },
        ],
        password: [
          {max: 50, message: '长度不能超过50个字符',  trigger: 'change'}
        ],
        username: [
          {max: 50, message: '长度不能超过50个字符',  trigger: 'change'},
          {required: true, message: '不能为空',  trigger: 'change'}
        ],
        role: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'},
          {required: true, message: '不能为空',  trigger: 'change'}
        ],
        realNamer: [
          {max: 10, message: '长度不能超过10个字符',  trigger: 'change'},
          {required: true, message: '不能为空',  trigger: 'change'}
        ],
        idCard: [
          {min:15,max:18, message: '身份证长度不正确',  trigger: 'change'}
        ],
        phone: [
          {pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '手机号码格式不正确',  trigger: 'change'},
		   {required: true, message: '不能为空',  trigger: 'change'}
        ],
    },
  };
  },
  components: {
SelectLink
},
computed: {
  // 由于子组件不能修改继承来的对象，所以重新复制一份
  form: function () {
    return this.params.data
  }
},
created() {
  
},
// 父组件中继承过来的事件
emits: ["onClose","getTableData"],
    methods: {
  
  // 提交事件
  submitForm(formName) {
    var that = this

    this.$refs[formName].validate((valid) => {

      if (valid) {

        if (this.params.type == 'add') {
          //console.log('+++++++++++++++++++++++++++++++++'+this.$refs[formName])

          axios.post('/User/Create', this.form).then(() => {
            ElMessage.success('添加成功')
            that.$emit('onClose')
            that.$emit('getTableData')
          })
        } else {
          axios.post('/User/Modify?id='+this.form.id, this.form).then(() => {
            ElMessage.success('修改成功')
            that.$emit('onClose')
            that.$emit('getTableData')
          })
        }
      }
    })
  },
  // 返回事件
  onCacel() {
    this.$emit('onClose')
  }
}
}
</script>