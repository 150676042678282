<template>
  <el-dialog
      :title="params.title"
      :model-value="params.show"
      @close="onCacel"
      width="800px" center>
    <el-form :model="form" label-width="120px" class="ruleForm">
      <div style="display: grid; grid-template-columns: repeat(2, 50%);" >
        


        <el-form-item label="用户名" prop="username">
         <el-input :modelValue="form.username" readonly></el-input>
        </el-form-item>

        <el-form-item label="角色"  prop="role">
         <el-input :modelValue="form.role" readonly></el-input>
        </el-form-item>

        <el-form-item label="姓名" prop="realNamer">
         <el-input :modelValue="form.realNamer" readonly></el-input>
        </el-form-item>

        <el-form-item label="生日"  prop="birthday">
         <el-input :modelValue="form.birthday" readonly></el-input>
        </el-form-item>

<!--        <el-form-item label="学历"  prop="education">-->
<!--         <el-input :modelValue="form.education" readonly></el-input>-->
<!--        </el-form-item>-->

        <el-form-item label="身份证"  prop="idCard">
         <el-input :modelValue="form.idCard" readonly></el-input>
        </el-form-item>

        <el-form-item label="性别"  prop="sex">
         <el-input :modelValue="form.sex" readonly></el-input>
        </el-form-item>

        <el-form-item label="手机号码"  prop="phone">
         <el-input :modelValue="form.phone" readonly></el-input>
        </el-form-item>

        
        <el-form-item label="电子邮箱" prop="email">
         <el-input  v-model="form.email" readonly ></el-input>
        </el-form-item>

      </div>
      <el-form-item>
        <el-button @click="onCacel()">返回</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import axios from '@/utils/axios'
import {ElMessage} from 'element-plus'


export default {
  name: "Form",
  // 继承父窗体列表页的数据
  props: {
    params: Object
  },
  data() {
    return {
      // 全局变量，用于获得配置信息等
      global: window.global,

      // 更多选项
      option: {
    
  },
    // 验证选项
    rules: {},
  };
  },
  components: {

},
computed: {
  // 由于子组件不能修改继承来的对象，所以重新复制一份
  form: function () {
    var formData = this.params.data
    
    return formData
  }
},
created() {
  
},
// 父组件中继承过来的事件
emits: ["onClose","getTableData"],
    methods: {
  
  // 返回事件
  onCacel() {
    this.$emit('onClose')
  }
}
}
</script>