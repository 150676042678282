<template>
  <div class="connent_title">
    <div class="connent_header">
      <span><a href="/User/User/List" >用户信息</a></span>
    </div>
  </div>
  <el-form :inline="true" style="margin-top: 20px">

		<el-form-item label="用户名">
			<el-input v-model="searchData.username" placeholder="请选择用户名"></el-input>
		</el-form-item>
		<el-form-item label="角色">
			<SelectLink url="/Role/List" :selected="searchData.role" field="name" @change="(val)=>{searchData.role_id = val.key}"></SelectLink>
		</el-form-item>
		<el-form-item label="姓名">
			<el-input v-model="searchData.realNamer" placeholder="请选择姓名"></el-input>
		</el-form-item>


		<el-form-item label="身份证">
			<el-input v-model="searchData.idCard" placeholder="请选择身份证"></el-input>
		</el-form-item>
<!--		<el-form-item label="性别">-->
<!--			<el-select v-model="searchData.sex" placeholder="请选择性别">-->
<!--					<el-option key="全部" label="全部" value=""/>-->
<!--					<el-option v-for="item in global.dict.Sex" :key="item" :label="item" :value="item"></el-option>-->
<!--			</el-select>-->
<!--		</el-form-item>-->
		<el-form-item label="手机号码">
			<el-input v-model="searchData.phone" placeholder="请选择手机号码"></el-input>
		</el-form-item>
    <el-form-item label="创建时间">
      <el-date-picker
          v-model="searchData.createdDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button-group>
        <el-button type="success" @click="onSearch" v-hasPermission="'/Api/User/List'">查询</el-button>
        <el-button type="primary" @click="onCreate" v-hasPermission="'/Api/User/Create'">增加</el-button>
      </el-button-group>
    </el-form-item>
  </el-form>

  <edit_view
      :params="edit_view"
      @getTableData="getTableData"
      @onClose="onClose"/>
  <info_view
      :params="info_view"
      @getTableData="getTableData"
      @onClose="onClose"/>

  <el-table
      :data="tableData"
      v-loading="loading"
      style="width: 100%;border: 1px solid #EBEEF5;border-radius: 5px;">
    <el-table-column key="username" label="用户名" prop="username"></el-table-column>
		<el-table-column key="role" label="角色" prop="role"></el-table-column>
		<el-table-column key="realNamer" label="姓名" prop="realNamer"></el-table-column>
    <el-table-column key="currentState" label="当前状态" prop="currentState">
      <template #default="scope">
        <span v-if="scope.row.currentState == 0">不可远程不可现场</span>
        <span v-if="scope.row.currentState == 1">仅可远程</span>
        <span v-if="scope.row.currentState == 2">仅可现场</span>
        <span v-if="scope.row.currentState == 3">远程&现场</span>
      </template>
    </el-table-column>
<!--		<el-table-column key="education" label="学历" prop="education"></el-table-column>-->
		<el-table-column key="idCard" label="身份证" prop="idCard"></el-table-column>
		<el-table-column key="sex" label="性别" prop="sex"></el-table-column>
		<el-table-column key="phone" label="手机号码" prop="phone"></el-table-column>
    <el-table-column key="createdDate" label="创建时间" prop="createdDate"></el-table-column>
    <el-table-column label="操作" min-width="110">
      <template #default="scope">
        <el-button-group>
          <el-button
              size="mini"
              type="success"
              @click="onInfo(scope.$index, scope.row)" v-hasPermission="'/Api/User/List'">查看
          </el-button>
          <el-button
              size="mini"
              type="primary"
              @click="onEdit(scope.$index, scope.row)" v-hasPermission="'/Api/User/Modify'">编辑
          </el-button>
          <el-popconfirm title="确定删除吗？" @confirm="onDelete(scope.row)" >
            <template #reference>
              <el-button
                  size="mini"
                  type="danger" v-hasPermission="'/Api/User/Remove'">删除
              </el-button>
            </template>
          </el-popconfirm>
        </el-button-group>
      </template>
    </el-table-column>
  </el-table>

  <el-pagination
      background
      :pager-count="11"
      layout="total, prev, pager, next, jumper"
      :total="pageData.total"
      :page-size="pageData.size"
      :current-page="pageData.current"
      @current-change="changePage">
  </el-pagination>

</template>

<script>

import axios from '@/utils/axios'
import edit_view from './Form'
import info_view from './Info'
import {ElMessage} from 'element-plus'
import SelectLink from "@/components/SelectLink"
import VueCookies from "vue-cookies";

export default {
  data() {
    return {
      // 全局变量，用于获得配置信息等
      global: window.global,

      loading: false,
      // 编辑窗口组件
      edit_view: {
        show: false, // 是否显示
        type: "", // add or edit
        title: "", //标题
        data: {} //数据
      },
      // 查看窗口组件
      info_view: {
        show: false, // 是否显示
        type: "", // add or edit
        title: "", //标题
        data: {} //数据
      },
      // 分页信息
      pageData: window.global.config.page,
      // 搜索区内容
      searchData: {},
      searchOptions: {},
      // 表格数据
      tableData: [],

  }
  },
  components: {
    edit_view,// 编辑窗口组件
    info_view,// 查看窗口组件
SelectLink
},
created() {
  this.getTableData()
},
methods: {
  // 搜索事件
  onSearch() {
    this.getTableData()
  },
  // 增加事件
  onCreate() {
    this.edit_view.title = "新增";
    this.edit_view.type = 'add'
    this.edit_view.data = {}
    this.edit_view.show = true;
  },
  // 查看事件
  onInfo(index, row) {
    this.info_view.title = "详情";
    this.info_view.data = row
    this.info_view.show = true;
  },
  // 删除事件
  onDelete(row) {
    VueCookies.set("fackid",row.fackId, 60 * 60 * 2)
    this.loading = true
    axios.post('/User/Remove', {
      id: row.id
    }).then(() => {
      ElMessage.success('删除成功')
      this.getTableData()
    }).catch(() => {
      this.getTableData()
    })
  },
  // 编辑事件
  onEdit(index, row) {
    this.edit_view.title = "修改";
    this.edit_view.type = 'edit'
    this.edit_view.data = row;
    this.edit_view.show = true;
  },
  // 返回事件
  onClose() {
    this.edit_view.show = false;
    this.info_view.show = false;
  },
  // 获取列表
  getTableData() {
    this.loading = true
    var that = this
    axios.get('/User/List', {
      params: Object.assign({}, this.searchData, this.pageData)
    }).then(res => {
      that.tableData = res.data.list
      that.pageData.total = res.data.total
      that.loading = false
    }).catch(error => {
      that.loading = false
    })
  },
  // 分页事件
  changePage(page) {
    this.pageData.current = page
    this.getTableData()
  }

}
}
</script>